<template>
  <div class="login">
    <div class="content-box">
      <div class="login-head">
        <img src="@/static/login/bg.png"/>
        <img src="@/static/login/logo.png" class="logo"/>
        <img src="@/static/login/ic_close@2x.png" class="close" @click="seeBack"/>
      </div>
      <div v-if="envType === 'develop' || envType === 'test'" class="l-item-con f18 facebook f-csp flexible aItem-center" @click="visitorloginFun()">
        <span class="font-MT">遊客登錄</span>
      </div>
      <div v-if="!isandriodbtn" class="l-item-con f18 facebook f-csp flexible aItem-center" @click="onFBLogin()">
        <img src="@/static/login/ic_facebook.png"/>
        <span class="font-MT">通過Facebook登錄</span>
      </div>
      <div v-if="isandriodbtn" class="l-item-con f18 facebook f-csp flexible aItem-center" @click="onFBLoginandriod()">
        <img src="@/static/login/ic_facebook.png"/>
        <span class="font-MT">通過Facebook登錄</span>
      </div>
      <div class="loginTip">
        * 點擊登錄代表同意<router-link to="/userAgree">《嗨文用戶協議》</router-link> 及 <router-link to="/privacy">《隱私政策》</router-link>
      </div>
      <div class="login-footer">
        <img src="@/static/login/bg1.png"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  setCookie,
  delCookie
} from "utils/core/kernel";
import { phonelogin, facebooklogin,authfacebook } from "apiurl/user";
import {getToken} from "../../utils/storage";
import urlencode from "urlencode";
export default {
  data() {
    return {
      /*facebook*/
      fbSignInParams: {
        scope: 'email,user_likes',
        return_scopes: true
      },
      fbParams: {},
      envType: '',
      isandriodbtn: false,
      redirectUrl: ''
    };
  },
  created() {
    this.$nextTick(() => {
      this.envType = process.env.VUE_APP_TITLE
      var ua = window.navigator.userAgent.toLowerCase()
      var ios = /iphone|ipod|ipad/.test(ua)
      if (ua.indexOf("FBAV") > -1) {
        this.isandriodbtn = true
      } else {
        this.isandriodbtn = false
        this.loadFBSDK()
      }
    });
  },
  mounted() {
    let url = window.location.href
    let urlStr = url.split('?')[1]
    const urlSearchParams = new URLSearchParams(urlStr)
    const result = Object.fromEntries(urlSearchParams.entries())
    if (result.code) {
      this.getauthfacebook(result.code)
      this.redirectUrl = result.state.substring(0, result.state.indexOf('#'))
    }
  },
  methods: {
    seeBack() {
      if (this.$route.query.redirect) {
        this.$router.replace(this.$route.query.redirect);
      } else {
        this.$router.replace("/");
      }
    },
    loadFBSDK() {
      const params = `#version=v3.3&appId=587975796173278&status=true&cookie=true&xfbml=true&autoLogAppEvents=true`;
      (function(d, s, id) { // Load the SDK asynchronously
        var js; var fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s); js.id = id
        js.src = 'https://connect.facebook.net/zh_TW/sdk.js' + params
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'facebook-jssdk'))
    },
    onFBLogin() {
      const _this = this
      // 檢測FB登錄狀態
      FB.getLoginStatus(function(response) { // Called after the JS SDK has been initialized.
        console.log(response)
        if (response.status === 'connected') { // 已登錄
          _this.loginType = 7
          _this.fbParams.thirdToken = response.authResponse.accessToken
          _this.fbParams.thirdType = 7
          _this.fbParams.thirdUserId = response.authResponse.userID
          _this.loginFun()
        } else { // 未登錄進行登錄
          FB.login(function(res) {
            console.log(res)
            if (res.status === 'connected') {
              _this.loginType = 7
              _this.fbParams.thirdToken = res.authResponse.accessToken
              _this.fbParams.thirdType = 7
              _this.fbParams.thirdUserId = res.authResponse.userID
              _this.loginFun()
            } else {

            }
          })
        }
      })
    },
    onFBLoginandriod () {
      if (!getToken()) {
        if (this.envType === 'develop' || this.envType === 'test'){
          window.location.href = 'https://www.facebook.com/v15.0/dialog/oauth?client_id=587975796173278&redirect_uri=https%3A%2F%2Ftest-m.blackrockread.com%2F%23%2FphoneLogin&state='+urlencode(this.$route.query.redirect)
        }else{
          window.location.href = 'https://www.facebook.com/v15.0/dialog/oauth?client_id=587975796173278&redirect_uri=https%3A%2F%2Fm.blackrockread.com%2F%23%2FphoneLogin&state='+urlencode(this.$route.query.redirect)
        }
      }
    },
    // 登錄回調
    loginFun () {
      let data = {}
        data = {
          facebookToken: this.fbParams.thirdToken,
          thirdUserId: this.fbParams.thirdUserId,
          thirdUserName: '',
          confirm: '',
          fbUserName: '',
          headUrl: '',
        }
      facebooklogin(data)
          .then(res => {
              if (res.success) {
                if (this.checkValue) {
                  setCookie("maccount", this.loginValue.account, 7);
                } else {
                  delCookie("maccount");
                }
                this.$store.dispatch("saveInfo", res.data);
                if (this.$route.query.redirect) {
                  this.$router.replace(this.$route.query.redirect);
                } else {
                  this.$router.replace("/");
                }
              } else {
                if (res.code == 20001) {
                  this.$router.replace("/");
                }
              }
            })
            .catch(() => {});
    },
    getauthfacebook (authcode) {
      authfacebook({code: authcode}).then(res=>{
        if (res.success) {
          if (this.checkValue) {
            setCookie("maccount", this.loginValue.account, 7);
          } else {
            delCookie("maccount");
          }
          this.$store.dispatch("saveInfo", res.data);
          if (this.$route.query.redirect) {
            this.$router.replace(this.$route.query.redirect);
          } else if (this.redirectUrl != '') {
            this.$router.replace(this.redirectUrl);
          } else {
            this.$router.replace("/");
          }
        } else {
          if (res.code == 20001) {
            this.$router.replace("/");
          }
        }
      })
    },
    visitorloginFun () {
      phonelogin({account: '18201299033', code: '1234'})
              .then(res => {
                if (res.success) {
                  if (this.checkValue) {
                    setCookie("maccount", this.loginValue.account, 7);
                  } else {
                    delCookie("maccount");
                  }
                  this.$store.dispatch("saveInfo", res.data);
                  if (this.$route.query.redirect) {
                    this.$router.replace(this.$route.query.redirect);
                  } else if (this.redirectUrl) {
                    this.$router.replace(this.redirectUrl);
                  } else {
                    this.$router.replace("/");
                  }
                } else {
                  if (res.code == 20001) {
                    this.$router.replace("/");
                  }
                }
              })
              .catch(() => {});
    },
  }
};
</script>
<style>
.content-box .formWrap .item .blue {
  color: #2e93f3;
}
</style>

<style lang="stylus" scoped></style>
